import http from '../utils/http'

export const getDapingShow = (params) => http.get('/daping/show', params)

export const getDapingShowTop = (params) => http.get('/daping/show_top', params)

export const getDapingDetail= (params) => http.get('/daping/show_info', params)

export const getDapingCloseTop= (params) => http.get('/daping/close_top', params)

export const getBirthdayWall = (params) => http.get('/birthday-wall/detail', params)

//大屏抽奖
export const getDapingLotteryActShow = (params)=> http.get('/dapingLottery/act_show', params)

export const getDapingLotteryJoinUser = (params)=> http.get('/dapingLottery/join_user', params)

export const getDapingLotteryRewardInfo = (params)=> http.get('/dapingLottery/reward_info', params)

export const getDapingLotteryWinUser = (params)=> http.get('/dapingLottery/win_user', params)

//街景H5
export const getCouponByStreet = (params)=> http.post('/street/get_coupon_by_street', params)
