<template>
    <div class="mobileBox" v-if="isGetCoupon">
        <img class="streetLayer" src="https://img.chinamallsales.com/streetView/streetLayerBg.png"  alt="img">
        <img class="streetBg" src="https://img.chinamallsales.com/streetView/streetBg.png"  alt="img">
        <div class="operate">
            <div class="price textOverflow">
                <text class="priceLabel">￥</text>
                <text class="priceNumber">{{couponInfo.discount_amt}}</text>
            </div>
            <div class="info">
                <p class="infoName textOverflow">{{couponInfo.act_name}}</p>
                <p class="threshold textOverflow">{{couponInfo.show_name}}</p>
                <p class="time textOverflow">有效期：{{couponInfo.take_effect_time}}</p>
            </div>
            <div class="useBtn"  @click="backWxChat">
                <img src="https://img.chinamallsales.com/streetView/useBtn.png">
            </div>
        </div>
    </div>
    <!--    获取失败-->
    <div class="failBox flex-box-wrap" v-if="isFail">
        <div class="errorImg">
            <img :src="errorImg">
        </div>
        <div class="errorTitle">
            <text class="errorText" v-if="errorStatus==404">您已领取奖品了哦～</text>
            <text>{{errorStatus==403?'您访问的页面丢失了～':errorStatus==404?'不能太贪心哟～':'糟糕，网络不给力，请刷新试试～'}}</text>
        </div>
        <div class="errorOperate flexCenter">
            <div class="toChat" v-if="errorStatus==403" @click="backWxIndex">去逛逛</div>
            <div class="toChat" v-else-if="errorStatus==404" @click="backWxChat">去使用</div>
            <div class="retry" v-else @click="getCoupon">重试</div>
        </div>
    </div>
</template>
<script>
    import 'amfe-flexible'  //移动端rem适配
    import '../../utils/rem'
    import wx from 'weixin-js-sdk';
    import {getCouponByStreet} from "@/api/remarkMessage";
    import {showLoading,hideLoading} from "@/utils/loading";
    export default {
        data() {
            return {
                title: '领取奖券',
                isGetCoupon:false,
                isFail:false,
                errorStatus:0,
                errMsg:'',
                errorImg:'',
                titleUrl:'',
                timestamp:null,
                award_type:null,
                award_info:null,
                check_code:null,
                couponInfo:{
                    act_name:'',
                    discount_amt:0,
                    show_name :'',
                    take_effect_time:''
                }
            };
        },
        mounted(){
            document.title="奖励发放"
            this.award_type = this.$route.query.awardType;
            this.award_info = this.$route.query.awardInfo;
            this.check_code = this.$route.query.checkCode;
            this.timestamp = this.$route.query.t;
            if(this.timestamp){
                wx.miniProgram.navigateTo({url: `/v2/pages/v2Coupon/v2Coupon`});
                return
            }else{
                this.getCoupon();
            }
        },
        methods: {
           async getCoupon(){
               await showLoading();
               await getCouponByStreet({award_type: this.award_type ,award_info: this.award_info,check_code:this.check_code }).then(res=>{
                   setTimeout(() => {  hideLoading() }, 1500)
                   if(res.status==200){
                       this.couponInfo = res.data;
                       this.isGetCoupon  = true;
                       this.isFail = false;
                   }else{
                       //401 网络  402 界面丢失不可重新获取  403界面丢失可重新获取  404已经领取
                       this.isGetCoupon  = false;
                       this.isFail = true;
                       this.errorStatus=res.status;
                       this.errMsg = res.msg;
                       let imgString = res.status==404?'received':res.status==403?'noPage':'noNetwork';
                       this.errorImg = 'https://img.chinamallsales.com/streetView/'+imgString+'.png'
                   }
               });
           },
            backWxChat(){
                 wx.miniProgram.navigateTo({url: `/v2/pages/v2Coupon/v2Coupon`})
                // wx.miniProgram.navigateTo({url: `/v2/pages/v2Coupon/v2Coupon?h5Id=${this.couponInfo.id}`})
            },
            backWxIndex(){
                wx.miniProgram.switchTab({url: `/pages/gather/index/index`})
            }
        }
    }
</script>

<style  lang="less" scoped>
    .mobileBox{
        width:100%;
        height:148rem;
        background: linear-gradient(180deg, #FEEAC8 0%, #F2BA7C 100%);
        position: relative;
        img.streetLayer{
            position:absolute;
            width:100%;
            height:84.5838rem;
            bottom:0;
            left:0;
            z-index:2;
        }
        img.streetBg{
            position:absolute;
            width:75rem;
            height:141.2rem;
            bottom:4.792rem;
            left:0;
            z-index:3;
        }
        .operate{
            position:absolute;
            width:62.4rem;
            height:95.8rem;
            top:33rem;
            left:6.4rem;
            z-index:4;
            .price{
                width:52rem;
                height:25.2rem;
                margin:0 auto;
                color:#EC1B2E;
                font-weight: 600;
                text-align:center;
                text.priceLabel{
                    font-size:8rem;
                }
                text.priceNumber{
                    font-size:18rem;
                }
            }
            .info{
                width:52rem;
                height:13rem;
                margin:8.4rem auto 0  auto;
                p{
                    color:#840406;
                    text-align:center;
                    margin:0;
                    padding:0;
                }
                p.infoName{
                    width:100%;
                    height:5rem;
                    font-size:3.6rem;
                    font-weight: 600;
                }
                p.threshold{
                    margin-top:1.2rem;
                    font-size:2.4rem;
                }
                p.time{
                    font-size:2rem;
                    margin-top:0.5rem;
                }
            }
            .useBtn{
                margin-top:29.35rem;
                width:100%;
                height:19.6rem;
                margin-bottom:0;
                img{
                    width:100%;
                    height:100%;
                }
            }
        }
    }
    .textOverflow{
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
    }
    .failBox{
        width:100%;
        min-height:100%;
        background:#ffffff;
        .errorImg{
            width:24rem;
            height:20rem;
            img{
                width:100%;
                height:100%;
            }
        }
        .errorTitle{
            width:100%;
            min-height:2.8rem;
            font-size:2.083rem;
            text-align:center;
            color:#AAAAAA;
            margin-top:2rem;
            text{
                display: block;
            }
            text.errorText{
                color:#333;
                font-size:2.4rem;
                margin-bottom:1rem;
            }
        }
        .errorOperate{
            width:30rem;
            height:13.2rem;
            div{
                width:13.6rem;
                height:4.9rem;
                line-height:4.9rem;
                font-size:2.4rem;
                text-align:center;
                border-radius:2.4rem;
            }
            .retry{
                border:0.2rem solid #DDDDDD;
                color:#333;
            }
            .toChat{
                background: linear-gradient(90deg, #FF9973 0%, #FF4F4F 100%);
                color:#fff;
            }
        }
    }
    .flex-box-wrap{
        display:flex;
        align-items: center;
        justify-content: center;
        align-content:center;
        flex-wrap:wrap;
    }
    .flexCenter{
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
