/**
 * 全局loading效果：合并多次loading请求，避免重复请求
 * 当调用一次showLoading，则次数+1；当次数为0时，则显示loading
 * 当调用一次hideLoading，则次数-1; 当次数为0时，则结束loading
 */
import { ElLoading } from 'element-plus';
const defaultOptions = {
    lock: false,
    text: '正在加载',
    background: 'rgba(255, 255, 255, 1)',
    customClass:'loadingclass'
}
let loadingRequestCount = 0;
let loadingInstance;

const showLoading = () => {
    if (loadingRequestCount === 0) {
        loadingInstance = ElLoading.service(defaultOptions);
    }
    loadingRequestCount++
}

const hideLoading = () => {
    if (loadingRequestCount <= 0) return
    loadingRequestCount--
    if (loadingRequestCount === 0) {
        loadingInstance.close();
    }
}
export {
    showLoading,
    hideLoading
}
